import React from "react";
import styled from "styled-components";
// Components
import ProjectBox from "./ProjectBox";
// Assets
import ProjectImg1 from "../../assets/img/projects/1.png";
import ProjectImg2 from "../../assets/img/projects/2.png";
import ProjectImg3 from "../../assets/img/projects/3.png";
import ProjectImg4 from "../../assets/img/projects/4.png";
import ProjectImg5 from "../../assets/img/projects/5.jpg";
import ProjectImg6 from "../../assets/img/projects/6.jpg";
import AddImage2 from "../../assets/img/add/add2.png";
import { useTranslation } from 'react-i18next';

export default function Projects() {
  const { t } = useTranslation();
  return (
    <Wrapper id="projects">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">¿CÓMO FUNCIONA?</h1>
          </HeaderInfo>
          <div className="row textCenter">
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg1}
                title="Registro de usuario"
                text="Nuestro equipo te ayudará a registrar tu cuenta de WhatsApp con nosotros. Completa los datos básicos sobre ti y escanea el código QR que se muestra en la aplicación de WhatsApp y listo!"
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg2}
                title={t('Elige los grupos para compartir')}
                text="Recopilamos solo los datos que deseas compartir con nosotros. Elige los grupos de WhatsApp que deseas compartir con nosotros y comenzaremos a recopilar datos de esos grupos."
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg3}
                title="Tus datos son anónimos"
                text="Todos los datos se anonimizan con las mejores herramientas de Google para la anonimización, lo que significa que ninguna información de identificación personal se almacena en nuestro servidor. Recopilamos datos periódicamente durante el periodo que elijas."
              />
            </div>
          </div>
          <div className="row textCenter">
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg4}
                title="Estadísticas del tablero"
                text="El tablero interactivo muestra todas las conversaciones individuales y los grupos de los que formas parte. Puedes seleccionar chats específicos para compartir con nosotros. El tablero también muestra actualizaciones en vivo sobre la cantidad de mensajes registrados, contactos compartidos y usuarios activos en la conversación."
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg5}
                title="Datos recopilados periódicamente"
                text="Recopilamos datos periódicamente durante el periodo que elijas. Puedes optar por detener la recopilación de datos en cualquier momento. No almacenaremos ningún dato después del periodo que elijas."
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg6}
                title="Utilizado para rastrear la desinformación"
                text="Esta información es utilizada por investigadores para rastrear la propagación de desinformación y noticias falsas en WhatsApp."
              />
            </div>
          </div>
        </div>
      </div>
      <div className="lightBg large-mt">
        <div className="container">
          <Advertising className="flexSpaceCenter">
            <AddLeft>
              <AddLeftInner>
                <ImgWrapper className="flexCenter">
                  <img className="radius8" src={AddImage2} alt="add" />
                </ImgWrapper>
              </AddLeftInner>
            </AddLeft>
            <AddRight>
              <h2 className="font40 extraBold mb-2">¿Cómo te beneficia esto?</h2>
              <p className="font20">
              Los datos que compartas con el proyecto serán útiles para identificar cómo se difunde contenido en WhatsApp, algo que de otra manera es muy difícil de analizar. Tus datos serán anonimizados y tu información personal nunca será compartida ni almacenada.
              <br />
                
              </p>
              {/* <ButtonsRow
                className="flexNullCenter"
                style={{ margin: "30px 0" }}
              >
                <div style={{ width: "190px" }}>
                  <FullButton
                    title="Get Started"
  
                  />
                </div>
                <div style={{ width: "190px", marginLeft: "15px" }}>
                  <FullButton
                    title="Contact Us"
  
                    border
                  />
                </div>
              </ButtonsRow> */}
            </AddRight>
          </Advertising>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Advertising = styled.div`
  padding: 100px 0;
  margin: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 60px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;
const AddLeft = styled.div`
  position: relative;
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
  }
`;
const AddLeftInner = styled.div`
  width: 100%;
  position: absolute;
  top: -300px;
  left: 0;
  @media (max-width: 1190px) {
    top: -250px;
  }
  @media (max-width: 920px) {
    top: -200px;
  }
  @media (max-width: 860px) {
    order: 1;
    position: relative;
    top: -60px;
    left: 0;
  }
`;
const ImgWrapper = styled.div`
  width: 100%;
  padding: 0 15%;
  img {
    width: 100%;
    height: auto;
  }
  @media (max-width: 400px) {
    padding: 0;
  }
`;
